<template>
    <div class="upcoming-schedule-component">
        <appointment-thread-component
            v-bind="upcomingProps"
            :load-more-label="loadMoreLabel"
            @get-appointment-uuid="$emit('get-appointment-uuid', $event)"
            @set-tab-name="$emit('set-tab-name', $event)"
        />
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
    AppointmentThreadComponent,
} from '@CommonComponents';
import { AccountType } from '@Utilities/enum';
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";

export default {
    name: 'UpcomingScheduleComponent',
    components: {
        AppointmentThreadComponent,
    },
    props: {
        tabName: { type: String, default: '' },
        loadMoreLabel: { type: String, default: 'More appointments' },
    },
    emits: [ "get-appointment-uuid", "set-tab-name" ],
    setup(props) {
        const store = useStore();

        // UPCOMING APPOINTMENTS DATA
        const appointments = computed(() => {
            return store.getters['CommonAppointment/upcomingAppointmentList']
        });

        // UPCOMING APPOINTMENT BINDED PROPS
        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const upcomingProps = ref({
            title: userType.value === AccountType.Medrep ? "Upcoming Appointments" : "",
            type: "accepted",
            tabName: props.tabName,
            appointments: appointments,
            emptyTitle: "No upcoming appointments",
            uniqueId: "upcoming-schedule-read-more",
            hasInfiniteLoading: userType.value === AccountType.Medrep,
        })

        return {
            upcomingProps,
        }
    }
}
</script>

<style lang="scss">
.upcoming-schedule-component {
}
</style>